import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  img: {
    width: "50%",
    alignSelf: "center",
    objectFit: "contain",
    // margin: "30px 0 20px 0",
  },
});
